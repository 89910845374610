<template>
	<div class="Home">
		<div class="Home-box Home-iphone view-box">
			<div class="homePage1">
				<div class="page1Box connectBoxW">
					<div class="titleBox">
						<h1 class="bigTitle">The DeFi Hub on Blast</h1>
						<h6 class="smallTitle">with DEX+NFT+Launchpad+Spacebase, exploring the Layer2 ecosystem</h6>
						<ul class="linksList">
							<li><a href="https://twitter.com/blaspace_io" target="_block"><img src="@/assets/logos/titwerB.png" alt=""></a></li>
							<li><a href="https://discord.gg/nezjCa5j8r" target="_block"><img src="@/assets/logos/discordB.png" alt=""></a></li>
							<li><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block"><img src="@/assets/logos/YouTubeB.png" alt=""></a></li>
							<li><a href="https://medium.com/@BlaSpace" target="_block"><img src="@/assets/logos/mediumB.png" alt=""></a></li>
							<li><a href="https://docs.spacefi.io/" target="_block"><img src="@/assets/logos/docsB.png" alt=""></a></li>
						</ul>
						<div class="spaceNetworkList" style="display:none;">
							<div>
								<img src="@/assets/logos/zksyncLogo.png" alt="">
								<span>SpaceFi on Blast</span>
							</div>
							<div>
								<img src="@/assets/logos/scrollLogo.png" alt="">
								<span>SpaceFi on Scroll</span>
								<img src="@/assets/Home/other-web.png" alt="">
							</div>
						</div>
					</div>
					<div class="bottomBox">
						<ul>
							<li>
								<p>${{ TVL }}</p>
								<p>TVL</p>
							</li>
							<li>
								<p>${{ SPACEPrice }}</p>
								<p>SPACE Price</p>
							</li>
							<li>
								<img src="@/assets/Home/down-deful.png" alt="">
							</li>
							<li>
								<p>${{ marketCap }}</p>
								<p>Market Cap</p>
							</li>
							<li>
								<p>{{ circulating }}</p>
								<p>Circulating</p>
							</li>
						</ul>
					</div>
					<div class="noyes"></div>
				</div>
			</div>
			<div class="homePage2">
				<div class="noyes"></div>
				<div class="page2Box connectBoxW">
					<div class="titleBox">
						<h2 class="title">SPACE STATS</h2>
						<p class="titleTip">— — on Blast Sepolia Testnet — —</p>
						<div class="TVLBox">
							<p><span>$</span>{{ TVL }}</p>
							<p>TVL <span>(includes all LPs, mining pools and NFTs)</span></p>
						</div>
					</div>
					<div class="dataBox">
						<ul>
							<li>
								<p><span>$</span> {{ SPACEPrice }}</p>
								<p>{{tokenSymbol}} Price</p>
							</li>
							<li>
								<p><span>$</span> {{ marketCap }}</p>
								<p>Market Cap</p>
							</li>
							<li>
								<p>{{ circulating }}</p>
								<p>Circulating</p>
							</li>
							<li>
								<p>{{ currentTotalSupply }}</p>
								<p>Current Total Supply</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>{{ DAOLocked }}</p>
								<p>DAO Locked</p>
							</li>
							<li>
								<p>{{ tradeMiningReward }}</p>
								<p>Trade Mining Reward (Locked)</p>
							</li>
							<li>
								<p>{{ totalBurned }}</p>
								<p>Total Burned</p>
							</li>
							<li>
								<p>{{ currentEmissin }}/s</p>
								<p>Current Emissin</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="homePage3">
				<div class="noyes">
					<div class="cominsoon"><img src="@/assets/Home/comingsoon.png" alt="" srcset=""> coming soon</div>
				</div>
				<div class="page3Box connectBoxW">
					<div class="titleBox">
						<h2 class="title">BONUS</h2>
						<p class="titleTip">— — on Blast Sepolia Testnet — —</p>
					</div>
					<div class="dataBox">
						<ul>
							<li>
								<div class="title">
									<p class="titleIcon">B</p>
									<p>BONUS FOR bLP</p>
								</div>
								<div class="allData">
									<div class="smallTitle">
										<p><span></span>Total bLP Bonus of All</p>
										<p>{{ totalbLPBounsOfAll }}</p>
										<p>xSPACE</p>
									</div>
									<div></div>
								</div>
								<div class="border"></div>
								<div class="myData">
									<div class="smallTitle">
										<p><span></span>My bLP Bonus</p>
										<p>{{ mybLPBonus }}</p>
										<p>xSPACE</p>
									</div>
									<div class="smallTitle">
										<p>My Pending bLP Bonus</p>
										<p>{{ myPendingbLPBonus }}</p>
										<p>xSPACE</p>
									</div>
								</div>
								<div class="claim">CLAIM</div>
							</li>
							<li>
								<div class="title">
									<p class="titleIcon">N</p>
									<p>BONUS FOR PLANET NFT</p>
								</div>
								<div class="allData">
									<div class="smallTitle">
										<p><span></span>Total NFTs</p>
										<p>{{ totalNFTs }}</p>
										<p></p>
									</div>
									<div class="smallTitle">
										<p><span></span>Total Bonus of All</p>
										<p>{{ totalBonusOfAll }}</p>
										<p>xSPACE</p>
									</div>
								</div>
								<div class="border"></div>
								<div class="myData">
									<div class="smallTitle">
										<p><span></span>My Total Bonus</p>
										<p>{{ myTotalBonus }}</p>
										<p>xSPACE</p>
									</div>
									<div class="smallTitle">
										<p>My Pending Bonus</p>
										<p>{{ myPendingBonus }}</p>
										<p>xSPACE</p>
									</div>
								</div>
								<div class="claim">CLAIM</div>
							</li>
							<li>
								<div class="title">
									<p class="titleIcon">X</p>
									<p>BONUS FOR xSPACE</p>
								</div>
								<div class="allData">
									<div class="smallTitle">
										<p><span></span>Total xSPACE Bonus of All</p>
										<p>{{ totalxSPACEBounsOfAll }}</p>
										<p>xSPACE</p>
									</div>
									<div></div>
								</div>
								<div class="border"></div>
								<div class="myData">
									<div class="smallTitle">
										<p><span></span>My xSPACE Bonus</p>
										<p>{{ myxSPACEBonus }}</p>
										<p>xSPACE</p>
									</div>
									<div class="smallTitle">
										<p>My Pending xSPACE Bonus</p>
										<p>{{ myPendingxSPACEBonus }}</p>
										<p>xSPACE</p>
									</div>
								</div>
								<div class="claim">CLAIM</div>
							</li>
						</ul>
						
					</div>
				</div>
			</div>
			<div class="homePage4">
				<div class="noyes"></div>
				<div class="page4Box connectBoxW">
					<div class="titleBox">
						<h2 class="title">SPACEBASE</h2>
						<p class="titleTip">— — on Blast Sepolia Testnet — —</p>
					</div>
					<div class="dataBox">
						<ul>
							<li>
								<div class="title">
									<div class="titleLeft">
										<p class="titleIcon">T</p>
										<p>TOTAL</p>
									</div>
									<div >
										
									</div>
								</div>
								<div class="data">
									<div class="item">
										<p><span></span>Total Spacebase</p>
										<p>{{ totalSpacebase }}</p>
										<p></p>
									</div>
									<div class="item">
										<p><span></span>Total Members</p>
										<p>{{ totalMembers }}</p>
										<p></p>
									</div>
									<div class="item">
										<p><span></span>Total Rewards of Spacebases</p>
										<p>{{ totalRewardsOfSpacebaseSPACE }}</p>
										<p>SPACE</p>
									</div>
									<div class="item">
										<p></p>
										<p>{{ totalRewardsOfSpacebasexSPACE }}</p>
										<p>xSPACE</p>
									</div>
								</div>
							</li>
							<li>
								<div class="title">
									<div class="titleLeft">
										<p class="titleIcon">M</p>
										<p>MINE</p>
									</div>
									<div class="titleRight">
										<div class="claim">CLAIM</div>
									</div>
								</div>
								<div class="data">
									<div class="item">
										<p><span></span>My Total Rewards</p>
										<p>{{ myTotalRewardsSPACE }}</p>
										<p>SPACE</p>
									</div>
									<div class="item">
										<p></p>
										<p>{{ myTotalRewardsxSPACE }}</p>
										<p>xSPACE</p>
									</div>
									<div class="item">
										<p><span></span>My Pending Rewards</p>
										<p>{{ myPendingRewardsSPACE }}</p>
										<p>SPACE</p>
									</div>
									<div class="item">
										<p></p>
										<p>{{ myPendingRewardsxSPACE }}</p>
										<p>xSPACE</p>
									</div>
								</div>
							</li>
						</ul>    
					</div>
				</div>
			</div>
			<div class="homePage5">
				<div class="page5Box connectBoxW">
					<div class="titleBox">
						<h2 class="title">PARTNER</h2>
						<p class="titleTip">— — Build with the best partners and builders — —</p>
					</div>
					<div class="dataBox">
						<ul>
						<!-- <li><a href="https://metamask.io/" target="_block"><img src="@/assets/logos/metamask.png" alt=""></a></li> -->
							<li><a href="https://www.okx.com/web3" target="_block"><img class="okxImg" src="@/assets/logos/okxwallet.png" alt=""></a></li>
							<!-- <li><a href="https://www.keplr.app/" target="_block"><img src="@/assets/logos/keplr.png" alt=""></a></li> -->
							<li><a href="https://across.to" target="_block"><img class="acrossImg" src="@/assets/logos/across.png" alt=""></a></li>
							<li><a href="https://socket.tech/" target="_block"><img class="socketImg" src="@/assets/logos/socket.png" alt=""></a></li>
							<li><a href="https://celer.network/" target="_block"><img class="celerImg" src="@/assets/logos/celer.png" alt=""></a></li>
							<li><a href="https://www.orbiter.finance" target="_block"><img class="orbiterImg" src="@/assets/logos/orbiter.png" alt=""></a></li>
							<li><a href="https://xy.finance/" target="_block"><img class="xyfinanceImg" src="@/assets/logos/xyfinance.png" alt=""></a></li>
							<li><a href="https://www.cosmostation.io/" target="_block"><img class="cosmostationImg" src="@/assets/logos/cosmostation.png" alt=""></a></li>
							<!-- <li><a href="https://walletconnect.com/" target="_block"><img src="@/assets/logos/walletconnect.png" alt=""></a></li> -->
							<!-- <li><a href="https://www.ledger.com/" target="_block"><img src="@/assets/logos/ledger.png" alt=""></a></li> -->
							<li class="more"><a href="" onclick="return false;"><img src="@/assets/logos/more.png" alt=""></a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="homePage6">
				<div class="page6Box connectBoxW">
					<div>
						<div class="boxLeft">
							<div class="footer-logo-box">
								<router-link to="/">
									<img src="@/assets/logos/headerLogo.png" alt="">
								</router-link>
							</div>
							<p>Blaspace is the DeFi hub on Blast with DEX+NFT+Spacebase+Launchpad, exploring the Layer2 ecosystem.</p>
							<ul class="linksList">
								<li><a href="https://twitter.com/blaspace_io" target="_block"><img src="@/assets/logos/titwerB.png" alt=""></a></li>
								<li><a href="https://discord.gg/nezjCa5j8r" target="_block"><img src="@/assets/logos/discordB.png" alt=""></a></li>
								<li><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block"><img src="@/assets/logos/YouTubeB.png" alt=""></a></li>
								<li><a href="https://medium.com/@BlaSpace" target="_block"><img src="@/assets/logos/mediumB.png" alt=""></a></li>
								<li><a href="https://docs.spacefi.io/" target="_block"><img src="@/assets/logos/docsB.png" alt=""></a></li>
							</ul>
						</div>
						<div class="boxRight">
							<ul>
								<li class="title"><a href="" onclick="return false;">PRODUCTS</a></li>
								<li><a href="https://swap.blaspace.io/#/swap" target="_self">Swap</a></li>
								<li><router-link to="/Pool" tag="a">Pool</router-link></li>
								<!-- <li><router-link to="/Farm" tag="a">Farm</router-link></li>
								<li><router-link to="/Mint" tag="a">NFT</router-link></li>
								<li><router-link to="/Spacebase" tag="a">Spacebase</router-link></li>
								<li><router-link to="/xSPACE" tag="a">xSPACE</router-link></li> -->
								<li class="noPage" @click="noPage">Farm</li>
								<li class="noPage" @click="noPage">NFT</li>
								<li class="noPage" @click="noPage">Spacebase</li>
								<li class="noPage" @click="noPage">xSPACE</li>
								<li><router-link to="/Launchpad" tag="a">Launchpad</router-link></li>
								<li><router-link to="/Bridge" tag="a">Bridge</router-link></li>
								<li class="noPage" @click="noPage">Points</li>
							</ul>
							<ul>
								<li><a href="" onclick="return false;">COMMUNITY</a></li>
								<li><a href="https://twitter.com/blaspace_io" target="_block">Twitter</a></li>
								<li><a href="https://discord.gg/nezjCa5j8r" target="_block">Discord</a></li>
								<li><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block">YouTube</a></li>
								<li><a href="https://medium.com/@BlaSpace" target="_block">Medium</a></li>
							</ul>
							<ul>
								<li><a href="" onclick="return false;">SUPPORT</a></li>
								<li><a href="https://docs.spacefi.io/" target="_block">Docs</a></li>
								<li><a href="https://drive.google.com/drive/folders/19CesXs5J8jv8GY3q-BmA5utbSQUPlD9g" target="_block">Media Kit</a></li>
								<li><a href="https://dexscreener.com/zksync/spacefi" target="_block">DEX Screener</a></li>
								<li><a href="https://www.geckoterminal.com/zksync/spacefi_zksync/pools" target="_block">GeckoTerminal</a></li>
								<li><a href="https://www.coingecko.com/en/coins/spacefi-evmos" target="_block">CoinGecko</a></li>
								<li><a href="https://coinmarketcap.com/currencies/spacefi-evmos/" target="_block">CoinMarketCap</a></li>
							</ul>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
	</div>
    
</template>  

<script>
import {changeNumber, numberToFixed} from '@/utils/function.js'
export default {
    name: "Home",
    data() {
        return {
			TVL: 0, SPACEPrice: 0, marketCap: 0, tradeMiningReward: 0, currentTotalSupply: 0, DAOLocked: 0, totalBurned: 0, circulating: 0, currentEmissin: 0,
			totalbLPBounsOfAll: 0, mybLPBonus: 0, myPendingbLPBonus: 0,
			totalNFTs: 0, totalBonusOfAll: 0, myTotalBonus: 0, myPendingBonus: 0,
			totalxSPACEBounsOfAll: 0, myxSPACEBonus: 0, myPendingxSPACEBonus: 0,
			totalSpacebase: 0, totalMembers: 0, totalRewardsOfSpacebaseSPACE: 0, totalRewardsOfSpacebasexSPACE: 0,
			myTotalRewardsSPACE: 0, myTotalRewardsxSPACE: 0, myPendingRewardsSPACE: 0, myPendingRewardsxSPACE: 0,
        }
    },
    created() { },
    mounted() {
		this.TVL = changeNumber( numberToFixed( this.TVL ) )
		this.SPACEPrice = changeNumber( numberToFixed( this.SPACEPrice ) )
		this.marketCap = changeNumber( numberToFixed( this.marketCap ) )
		this.tradeMiningReward = changeNumber( numberToFixed( this.tradeMiningReward ) )
		this.currentTotalSupply = changeNumber( numberToFixed( this.currentTotalSupply ) )
		this.DAOLocked = changeNumber( numberToFixed( this.DAOLocked ) )
		this.totalBurned = changeNumber( numberToFixed( this.totalBurned ) )
		this.circulating = changeNumber( numberToFixed( this.circulating ) )
		this.currentEmissin = changeNumber( numberToFixed( this.currentEmissin ) )
		this.totalbLPBounsOfAll = changeNumber( numberToFixed( this.totalbLPBounsOfAll ) )
		this.mybLPBonus = changeNumber( numberToFixed( this.mybLPBonus ) )
		this.myPendingbLPBonus = changeNumber( numberToFixed( this.myPendingbLPBonus ) )
		this.totalNFTs = changeNumber( numberToFixed( this.totalNFTs ) )
		this.totalBonusOfAll = changeNumber( numberToFixed( this.totalBonusOfAll ) )
		this.myTotalBonus = changeNumber( numberToFixed( this.myTotalBonus ) )
		this.myPendingBonus = changeNumber( numberToFixed( this.myPendingBonus ) )
		this.totalxSPACEBounsOfAll = changeNumber( numberToFixed( this.totalxSPACEBounsOfAll ) )
		this.myxSPACEBonus = changeNumber( numberToFixed( this.myxSPACEBonus ) )
		this.myPendingxSPACEBonus = changeNumber( numberToFixed( this.myPendingxSPACEBonus ) )
		this.totalSpacebase = changeNumber( numberToFixed( this.totalSpacebase ) )
		this.totalMembers = changeNumber( numberToFixed( this.totalMembers ) )
		this.totalRewardsOfSpacebaseSPACE = changeNumber( numberToFixed( this.totalRewardsOfSpacebaseSPACE ) )
		this.totalRewardsOfSpacebasexSPACE = changeNumber( numberToFixed( this.totalRewardsOfSpacebasexSPACE ) )
		this.myTotalRewardsSPACE = changeNumber( numberToFixed( this.myTotalRewardsSPACE ) )
		this.myTotalRewardsxSPACE = changeNumber( numberToFixed( this.myTotalRewardsxSPACE ) )
		this.myPendingRewardsSPACE = changeNumber( numberToFixed( this.myPendingRewardsSPACE ) )
		this.myPendingRewardsxSPACE = changeNumber( numberToFixed( this.myPendingRewardsxSPACE ) )
	},
    methods: {
		noPage(){
            this.$message({type:'warning',message:'Not Live on Blast Mainnet yet.'})
        },
    }
}
</script>