<template>
	<div class="Home">
		<!-- <canvas id="canvas"></canvas> -->
		<!-- <div class="Home-box Home-pc view-box" @wheel="gowheel"> -->
		<div class="Home-box Home-pc">
			<!-- <el-carousel ref="swiper" arrow="never"  height="100vh" direction="vertical" indicator-position="none" :autoplay="false" :loop="false"> -->
				<div class="homePage1">
					<div class="page1Bg">
						<img class="bg1" src="@/assets/Home/planet-p1-1.png" alt="">
						<img class="bg2" src="@/assets/Home/planet-p1-2.png" alt="">
						<img class="bg3" src="@/assets/Home/planet-p1-3.png" alt="">
						<img class="bg4" src="@/assets/Home/planet-p1-4.png" alt="">
					</div>
					<div class="page1Box connectBoxW">
						<div class="titleBox">
							<h1 class="bigTitle">The DeFi Hub on Blast</h1>
							<h6 class="smallTitle">with DEX+NFT+Launchpad+Spacebase, exploring the Layer2 ecosystem</h6>
							<!-- <h6 class="smallTitle"></h6> -->
							<ul class="linksList">
								<li class="externalLink"><a href="https://twitter.com/blaspace_io" target="_block"><span></span></a></li>
								<li class="externalLink"><a href="https://discord.gg/nezjCa5j8r" target="_block"><span></span></a></li>
								<li class="externalLink"><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block"><span></span></a></li>
								<li class="externalLink"><a href="https://medium.com/@BlaSpace" target="_block"><span></span></a></li>
								<li class="externalLink"><a href="https://docs.spacefi.io/" target="_block"><span></span></a></li>
							</ul>
							<div class="spaceNetworkList" style="display: none;">
								<div>
									<img src="@/assets/logos/zksyncLogo.png" alt="">
									<span>SpaceFi on Blast</span>
								</div>
								<div>
									<img src="@/assets/logos/scrollLogo.png" alt="">
									<span>SpaceFi on Scroll</span>
									<img src="@/assets/Home/other-web.png" alt="">
								</div>
							</div>
						</div>
						<div class="bottomBox connectBoxW">
							<ul>
								<li>
									<p>${{ TVL }}</p>
									<p>TVL</p>
								</li>
								<li>
									<p>${{ SPACEPrice }}</p>
									<p>{{tokenSymbol}} Price</p>
								</li>
								<li>
									<img @click="goNextP" src="@/assets/Home/down-deful.png" alt="">
								</li>
								<li>
									<p>${{ marketCap }}</p>
									<p>Market Cap</p>
								</li>
								<li>
									<p>{{ circulating }}</p>
									<p>Circulating</p>
								</li>
							</ul>
						</div>
						<div class="noyes"></div>
					</div>
				</div>
				<div class="homePage2">
					<div class="noyes"></div>
					<div class="page2Bg">
						<img class="bg1" src="@/assets/Home/planetLeft.png" alt="">
						<img class="bg2" src="@/assets/Home/Blueblock.png" alt="">
						<img class="bg3" src="@/assets/Home/Greenblock.png" alt="">
						<img class="bg4" src="@/assets/Home/Purpleblock.png" alt="">
					</div>
					<div class="page2Box connectBoxW">
						<div class="titleBox">
							<h2 class="title">{{tokenSymbol}} STATS</h2>
							<p class="titleTip">— — on Blast Sepolia Testnet — —</p>
							<div class="TVLBox">
								<p><span>$</span>{{ TVL }}</p>
								<p>TVL <span>(includes all LPs, mining pools and NFTs)</span></p>
							</div>
						</div>
						<div class="dataBox">
							<ul>
								<li>
									<p><span>$</span> {{ SPACEPrice }}</p>
									<p>{{tokenSymbol}} Price</p>
								</li>
								<li>
									<p><span>$</span> {{ marketCap }}</p>
									<p>Market Cap</p>
								</li>
								<li>
									<p>{{ circulating }}</p>
									<p>Circulating</p>
								</li>
								<li>
									<p>{{ currentTotalSupply }}</p>
									<p>Current Total Supply</p>
								</li>
							</ul>
							<ul>
								<li>
									<p>{{ DAOLocked }}</p>
									<p>DAO Locked</p>
								</li>
								<li>
									<p>{{ tradeMiningReward }}</p>
									<p>Trade Mining Reward (Locked)</p>
								</li>
								<li>
									<p>{{ totalBurned }}</p>
									<p>Total Burned</p>
								</li>
								<li>
									<p>{{ currentEmissin }}/s</p>
									<p>Current Emissin</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="homePage3" id="claim">

					<div class="noyes">

						<div class="cominsoon"><img src="@/assets/Home/comingsoon.png" alt="" srcset=""> coming soon</div>
					</div>
					<div class="page3Bg">
						<img class="bg1" src="@/assets/Home/planetRight.png" alt="">
						<img class="bg2" src="@/assets/Home/Blueblock.png" alt="">
						<img class="bg3" src="@/assets/Home/Greenblock.png" alt="">
						<img class="bg4" src="@/assets/Home/Purpleblock.png" alt="">
					</div>
					<div class="page3Box connectBoxW">
						<div class="titleBox">
							<h2 class="title">BONUS</h2>
							<p class="titleTip">— — on Blast Sepolia Testnet — —</p>
						</div>
						<div class="dataBox">
							<ul>
								<li>
									<div class="title">
										<p class="titleIcon">B</p>
										<p>BONUS FOR bLP</p>
									</div>
									<div class="allData">
										<div class="smallTitle">
											<p><span></span>Total bLP Bonus of All</p>
											<p>{{ totalbLPBounsOfAll }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
										<div></div>
									</div>
									<div class="border"></div>
									<div class="myData">
										<div class="smallTitle">
											<p><span></span>My bLP Bonus</p>
											<p>{{ mybLPBonus }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
										<div class="smallTitle">
											<p>My Pending bLP Bonus</p>
											<p>{{ myPendingbLPBonus }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
									</div>
									<div v-if="myPendingbLPBonus != 0" class="claim claimYes" @click="withdrawBool(0)">CLAIM <v-LoadingWarpBlack v-if="withdrawBool0Loading" style="margin-left: 3px;" :imgSize="1"></v-LoadingWarpBlack></div>
									<div v-else class="claim">CLAIM</div>
								</li>
								<li>
									<div class="title">
										<p class="titleIcon">N</p>
										<p>BONUS FOR PLANET NFT</p>
									</div>
									<div class="allData">
										<div class="smallTitle">
											<p><span></span>Total NFTs</p>
											<p>{{ totalNFTs }}</p>
											<p></p>
										</div>
										<div class="smallTitle">
											<p><span></span>Total Bonus of All</p>
											<p>{{ totalBonusOfAll }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
									</div>
									<div class="border"></div>
									<div class="myData">
										<div class="smallTitle">
											<p><span></span>My Total Bonus</p>
											<p>{{ myTotalBonus }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
										<div class="smallTitle">
											<p>My Pending Bonus</p>
											<p>{{ myPendingBonus }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
									</div>
									<div v-if="myPendingBonus != 0" class="claim claimYes" @click="withdrawBool(1)">CLAIM <v-LoadingWarpBlack v-if="withdrawBool1Loading" style="margin-left: 3px;" :imgSize="1"></v-LoadingWarpBlack></div>
									<div v-else class="claim">CLAIM</div>
								</li>
								<li>
									<div class="title">
										<p class="titleIcon">X</p>
										<p>BONUS FOR xSPACE</p>
									</div>
									<div class="allData">
										<div class="smallTitle">
											<p><span></span>Total xSPACE Bonus of All</p>
											<p>{{ totalxSPACEBounsOfAll }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
										<div></div>
									</div>
									<div class="border"></div>
									<div class="myData">
										<div class="smallTitle">
											<p><span></span>My xSPACE Bonus</p>
											<p>{{ myxSPACEBonus }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
										<div class="smallTitle">
											<p>My Pending xSPACE Bonus</p>
											<p>{{ myPendingxSPACEBonus }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
									</div>
									<div v-if="myPendingxSPACEBonus != 0" class="claim claimYes" @click="withdrawBool(2)">CLAIM <v-LoadingWarpBlack v-if="withdrawBool2Loading" style="margin-left: 3px;" :imgSize="1"></v-LoadingWarpBlack></div>
									<div v-else class="claim">CLAIM</div>
								</li>
							</ul>
							
						</div>
					</div>
				</div>
				<div class="homePage4">
					<div class="noyes"></div>
					<div class="page4Bg">
						<img class="bg1" src="@/assets/Home/planetLeft.png" alt="">
						<img class="bg2" src="@/assets/Home/Blueblock.png" alt="">
						<img class="bg3" src="@/assets/Home/Greenblock.png" alt="">
						<img class="bg4" src="@/assets/Home/Purpleblock.png" alt="">
					</div>
					<div class="page4Box connectBoxW">
						<div class="titleBox">
							<h2 class="title">SPACEBASE</h2>
							<p class="titleTip">— — on Blast Sepolia Testnet — —</p>
						</div>
						<div class="dataBox">
							<ul>
								<li>
									<div class="title">
										<div class="titleLeft">
											<p class="titleIcon">T</p>
											<p>TOTAL</p>
										</div>
										<div class="titleRight" style="opacity: 0;">
											<div class="claim claimYes">CLAIM  </div>
										</div>
									</div>
									<div class="data">
										<div class="item">
											<p><span></span>Total Spacebase</p>
											<p>{{ totalSpacebase }}</p>
											<p></p>
										</div>
										<div class="item">
											<p><span></span>Total Members</p>
											<p>{{ totalMembers }}</p>
											<p></p>
										</div>
										<div class="item">
											<p><span></span>Total Rewards of Spacebases</p>
											<p>{{ totalRewardsOfSpacebaseSPACE }}</p>
											<p>{{tokenSymbol}}</p>
										</div>
										<div class="item">
											<p></p>
											<p>{{ totalRewardsOfSpacebasexSPACE }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
									</div>
								</li>
								<li>
									<div class="title">
										<div class="titleLeft">
											<p class="titleIcon">M</p>
											<p>MINE</p>
										</div>
										<div class="titleRight">
											<div class="claim claimYes" v-if="myPendingRewardsSPACE != 0 || myPendingRewardsxSPACE != 0" @click="claimSpacebasePending">CLAIM  <v-LoadingWarpBlack style="margin-left: 3px;" v-if="claimSpacebaseLoading" :imgSize="1"></v-LoadingWarpBlack></div>
											<div class="claim" v-else>CLAIM</div>
										</div>
									</div>
									<div class="data">
										<div class="item">
											<p><span></span>My Total Rewards</p>
											<p>{{ myTotalRewardsSPACE }}</p>
											<p>{{tokenSymbol}}</p>
										</div>
										<div class="item">
											<p></p>
											<p>{{ myTotalRewardsxSPACE }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
										<div class="item">
											<p><span></span>My Pending Rewards</p>
											<p>{{ myPendingRewardsSPACE }}</p>
											<p>{{tokenSymbol}}</p>
										</div>
										<div class="item">
											<p></p>
											<p>{{ myPendingRewardsxSPACE }}</p>
											<p>{{xtokenSymbol}}</p>
										</div>
									</div>
								</li>
							</ul>
							
						</div>
					</div>
				</div>
				<div class="homePage5">
					<div class="page5Bg">
						<img class="bg2" src="@/assets/Home/Blueblock.png" alt="">
						<img class="bg3" src="@/assets/Home/Blueblock.png" alt="">
						<img class="bg4" src="@/assets/Home/Purpleblock.png" alt="">
					</div>
					<div class="page5Box connectBoxW">
						<div class="titleBox">
							<h2 class="title">PARTNER</h2>
							<p class="titleTip">— — Build with the best partners and builders — —</p>
						</div>
						<div class="dataBox">
							<ul>
								<!-- <li><a href="https://metamask.io/" target="_block"><img src="@/assets/logos/metamask.png" alt=""></a></li> -->
								<li><a href="https://www.okx.com/web3" target="_block"><img class="okxImg" src="@/assets/logos/okxwallet.png" alt=""></a></li>
								<!-- <li><a href="https://www.keplr.app/" target="_block"><img src="@/assets/logos/keplr.png" alt=""></a></li> -->
								<li><a href="https://across.to" target="_block"><img class="acrossImg" src="@/assets/logos/across.png" alt=""></a></li>
								<li><a href="https://socket.tech/" target="_block"><img class="socketImg" src="@/assets/logos/socket.png" alt=""></a></li>
								<li><a href="https://celer.network/" target="_block"><img class="celerImg" src="@/assets/logos/celer.png" alt=""></a></li>
								<li><a href="https://www.orbiter.finance" target="_block"><img class="orbiterImg" src="@/assets/logos/orbiter.png" alt=""></a></li>
								<li><a href="https://xy.finance/" target="_block"><img class="xyfinanceImg" src="@/assets/logos/xyfinance.png" alt=""></a></li>
								<li><a href="https://www.cosmostation.io/" target="_block"><img class="cosmostationImg" src="@/assets/logos/cosmostation.png" alt=""></a></li>
								<!-- <li><a href="https://walletconnect.com/" target="_block"><img src="@/assets/logos/walletconnect.png" alt=""></a></li> -->
								<!-- <li><a href="https://www.ledger.com/" target="_block"><img src="@/assets/logos/ledger.png" alt=""></a></li> -->
								<li class="more"><a href="" onclick="return false;"><img src="@/assets/logos/more.png" alt=""></a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="homePage6">
					<div class="page6Bg">
						<img src="@/assets/Home/plantBottom.png" alt="" srcset="">
					</div>
					<div class="page6Box connectBoxW">
						<div>
							<div class="boxLeft">
								<div class="footer-logo-box">
									<router-link to="/">
										<img src="@/assets/logos/headerLogo.png" alt="">
									</router-link>
								</div>
								<p>Blaspace is the DeFi hub on Blast with DEX+NFT+Spacebase+Launchpad, exploring the Layer2 ecosystem.</p>
								<ul class="linksList">
									<li class="externalLink"><a href="https://twitter.com/blaspace_io" target="_block"><span></span></a></li>
									<li class="externalLink"><a href="https://discord.gg/nezjCa5j8r" target="_block"><span></span></a></li>
									<li class="externalLink"><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block"><span></span></a></li>
									<li class="externalLink"><a href="https://medium.com/@BlaSpace" target="_block"><span></span></a></li>
									<li class="externalLink"><a href="https://docs.spacefi.io/" target="_block"><span></span></a></li>
								</ul>
							</div>
							<div class="boxRight">
								<ul>
									<li class="title"><a href="" onclick="return false;">PRODUCTS</a></li>
									<li><a href="https://swap.blaspace.io/#/swap" target="_self">Swap</a></li>
									<li><router-link to="/Pool" tag="a">Pool</router-link></li>
									<!-- <li><router-link to="/Farm" tag="a">Farm</router-link></li>
									<li><router-link to="/Mint" tag="a">NFT</router-link></li>
									<li><router-link to="/Spacebase" tag="a">Spacebase</router-link></li>
									<li><router-link to="/xSPACE" tag="a">xSPACE</router-link></li> -->
									<li class="noPage" @click="noPage">Farm</li>
									<li class="noPage" @click="noPage">NFT</li>
									<li class="noPage" @click="noPage">Spacebase</li>
									<li class="noPage" @click="noPage">xSPACE</li>
									<li><router-link to="/Launchpad" tag="a">Launchpad</router-link></li>
									<li><router-link to="/Bridge" tag="a">Bridge</router-link></li>
									<li class="noPage" @click="noPage">Points</li>
								</ul>
								<ul>
									<li class="title"><a href="" onclick="return false;">COMMUNITY</a></li>
									<li><a href="https://twitter.com/blaspace_io" target="_block">Twitter</a></li>
									<li><a href="https://discord.gg/nezjCa5j8r" target="_block">Discord</a></li>
									<li><a href="https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg" target="_block">YouTube</a></li>
									<li><a href="https://medium.com/@BlaSpace" target="_block">Medium</a></li>
								</ul>
								<ul>
									<li class="title"><a href="" onclick="return false;">SUPPORT</a></li>
									<li><a href="https://docs.spacefi.io/" target="_block">Docs</a></li>
									<li><a href="https://drive.google.com/drive/folders/19CesXs5J8jv8GY3q-BmA5utbSQUPlD9g" target="_block">Media Kit</a></li>
									<li><a href="https://dexscreener.com/zksync/spacefi" target="_block">DEX Screener</a></li>
									<li><a href="https://www.geckoterminal.com/zksync/spacefi_zksync/pools" target="_block">GeckoTerminal</a></li>
									<li><a href="https://www.coingecko.com/en/coins/spacefi-evmos" target="_block">CoinGecko</a></li>
									<li><a href="https://coinmarketcap.com/currencies/spacefi-evmos/" target="_block">CoinMarketCap</a></li>
								</ul>
							</div>
						</div>
						
					</div>
				</div>
			<!-- </el-carousel> -->
		</div>
		<message-model ref="messageModel"></message-model>
	</div>
    
</template>  

<script>

import messageModel from '@/components/Message.vue'
import {changeNumber, numberToFixed} from '@/utils/function.js'
import {judgeNetwork, getBalanceOf, burnNumber, getraiseSupply, getstarPerBlock, getBonusInfo, getUserReward, userRewardInfo, getNFTTotalSupply, getNodesLength, userNumber, getNodeAward, getNodexAward, awardNodeUsers, withdrawBool, handdleNodeWithdraw} from '@/utils/space.js'
export default {
    name: "Home",
	components:{
		messageModel
	},
    data() {
        return {
			userAddress:'',
            tokenSymbol:'',xtokenSymbol:'',contractAddress:'',
			allStar:30000000000000000000000000,
			one: true,
			SPACEInitialValue:0,
			TVL: 0, SPACEPrice: 0, marketCap: 0, tradeMiningReward: 0, currentTotalSupply: 0, DAOLocked: 0, totalBurned: 0, circulating: 0, currentEmissin: 0,
			totalbLPBounsOfAll: 0, mybLPBonus: 0, myPendingbLPBonus: 0,
			totalNFTs: 0, totalBonusOfAll: 0, myTotalBonus: 0, myPendingBonus: 0,
			totalxSPACEBounsOfAll: 0, myxSPACEBonus: 0, myPendingxSPACEBonus: 0,
			totalSpacebase: 0, totalMembers: 0, totalRewardsOfSpacebaseSPACE: 0, totalRewardsOfSpacebasexSPACE: 0,
			myTotalRewardsSPACE: 0, myTotalRewardsxSPACE: 0, myPendingRewardsSPACE: 0, myPendingRewardsxSPACE: 0,
			withdrawBool0Loading:false,withdrawBool1Loading:false,withdrawBool2Loading:false,
			claimSpacebaseLoading:false,
			
        }
    },
	computed:{
		
		
	},
    created() { 
		this.TVL = '0.000'//changeNumber( numberToFixed( this.TVL ) )
		this.SPACEPrice = '0.000'//changeNumber( numberToFixed( this.SPACEPrice ) )
		this.marketCap = '0.000'//changeNumber( numberToFixed( this.marketCap ) )
		this.tradeMiningReward = '0.000'//changeNumber( numberToFixed( this.tradeMiningReward ) )
		this.currentTotalSupply = '0.000'//changeNumber( numberToFixed( this.currentTotalSupply ) )
		this.DAOLocked = '0.000'//changeNumber( numberToFixed( this.DAOLocked ) )
		this.totalBurned = '0.000'//changeNumber( numberToFixed( this.totalBurned ) )
		this.circulating = '0.000'//changeNumber( numberToFixed( this.circulating ) )
		this.currentEmissin = '0.000'//changeNumber( numberToFixed( this.currentEmissin ) )
		this.totalbLPBounsOfAll = '0.000'//changeNumber( numberToFixed( this.totalbLPBounsOfAll ) )
		this.mybLPBonus = '0.000'//changeNumber( numberToFixed( this.mybLPBonus ) )
		this.myPendingbLPBonus = '0.000'//changeNumber( numberToFixed( this.myPendingbLPBonus ) )
		// this.totalNFTs = '0.000'//changeNumber( numberToFixed( this.totalNFTs ) )
		this.totalBonusOfAll = '0.000'//changeNumber( numberToFixed( this.totalBonusOfAll ) )
		this.myTotalBonus = '0.000'//changeNumber( numberToFixed( this.myTotalBonus ) )
		this.myPendingBonus = '0.000'//changeNumber( numberToFixed( this.myPendingBonus ) )
		this.totalxSPACEBounsOfAll = '0.000'//changeNumber( numberToFixed( this.totalxSPACEBounsOfAll ) )
		this.myxSPACEBonus = '0.000'//changeNumber( numberToFixed( this.myxSPACEBonus ) )
		this.myPendingxSPACEBonus = '0.000'//changeNumber( numberToFixed( this.myPendingxSPACEBonus ) )
		// this.totalSpacebase = '0.000'//changeNumber( numberToFixed( this.totalSpacebase ) )
		// this.totalMembers = '0.000'//changeNumber( numberToFixed( this.totalMembers ) )
		this.totalRewardsOfSpacebaseSPACE = '0.000'//changeNumber( numberToFixed( this.totalRewardsOfSpacebaseSPACE ) )
		this.totalRewardsOfSpacebasexSPACE = '0.000'//changeNumber( numberToFixed( this.totalRewardsOfSpacebasexSPACE ) )
		this.myTotalRewardsSPACE = '0.000'//changeNumber( numberToFixed( this.myTotalRewardsSPACE ) )
		this.myTotalRewardsxSPACE = '0.000'//changeNumber( numberToFixed( this.myTotalRewardsxSPACE ) )
		this.myPendingRewardsSPACE = '0.000'//changeNumber( numberToFixed( this.myPendingRewardsSPACE ) )
		this.myPendingRewardsxSPACE = '0.000'//changeNumber( numberToFixed( this.myPendingRewardsxSPACE ) )
	},
    mounted() {
		
		
		this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.contractAddress = window.contractAddr
        this.farmData = window.farmData
		
		// this.getNonusData()
		// this.getSpaceBaseData()
		setTimeout(() => {
            // this.getTokenStats()
        }, 4000);
		// this.$nextTick(()=> {
		// 	var hash = this.$route.hash
		// 	if(hash){
		// 		//console.log(hash.substring(1))
		// 		const position = document.getElementById(hash.substring(1));
		// 		position.scrollIntoView(true);
		// 	}
		// })
	},
    methods: {
		noPage(){
            this.$message({type:'warning',message:'Not Live on Blast Mainnet yet.'})
        },
		goNextP(){
			window.scrollTo({
				top: window.innerHeight,
				left: 0,
				behavior: "smooth",
			})
		},
		getTokenStats(){
			this.SPACEPrice = changeNumber( numberToFixed( window.farmData.tokenList[1].price ) )
			burnNumber(this.contractAddress.tokenAddress).then(burnNumberRes => {
				//console.log(burnNumberRes)
				this.totalBurned = changeNumber( numberToFixed( burnNumberRes/Math.pow(10,18) ) )
				//console.log(this.totalBurned)
				getraiseSupply(this.contractAddress.tokenAddress).then(raiseRes => {
					//console.log(raiseRes)
					var allStar = this.$BigNumber(raiseRes).plus(this.allStar).minus(burnNumberRes)
					this.currentTotalSupply =  changeNumber( numberToFixed( allStar.div(Math.pow(10,18)) ) )
					var circulating = this.$BigNumber(allStar).div(Math.pow(10,18))//.minus(this.$BigNumber(this.airdropBalance)).minus(this.$BigNumber(this.xstarTotalSupply))//.minus(this.$BigNumber(this.reserve2Balance)).minus(this.$BigNumber(this.reserve3Balance)).minus(this.$BigNumber(this.reserve4Balance)).minus(this.$BigNumber(this.reserve5Balance)).minus(this.$BigNumber(this.reserve6Balance)).minus(this.$BigNumber(this.privateBalance)).minus(this.$BigNumber(this.public1Balance)).minus(this.$BigNumber(this.public2Balance))
					this.circulating = changeNumber( numberToFixed( circulating ) )
					this.marketCap = changeNumber( numberToFixed( this.$BigNumber(circulating).times(this.SPACEPrice) ) )
				}).catch(error => {
					//console.log(error)
				})

			})
			getBalanceOf(this.contractAddress.tokenAddress,this.contractAddress.daoAddress).then(res => {
				getBalanceOf(this.contractAddress.xTokenAddress,this.contractAddress.daoAddress).then(xres => {
					this.DAOLocked = changeNumber( numberToFixed( (res + xres)/Math.pow(10,18) ) )
				})
			})
			getBalanceOf(this.contractAddress.tokenAddress,this.contractAddress.tmrAddress).then(res => {
				getBalanceOf(this.contractAddress.xTokenAddress,this.contractAddress.tmrAddress).then(xres => {
					//console.log(res,xres)
					this.tradeMiningReward = changeNumber( numberToFixed( (res + xres)/Math.pow(10,18) ) )
				})
			})
			getstarPerBlock(this.contractAddress.farmLibAddress).then(res => {
				this.currentEmissin = changeNumber( numberToFixed( (res )/Math.pow(10,18) ) )
			})
		},
		getNonusData(){
			getBonusInfo(this.contractAddress.devAddress,this.contractAddress.xTokenAddress).then(res => {
				//console.log(res)
				this.totalbLPBounsOfAll = res.blpBonus/Math.pow(10,18)
				this.totalBonusOfAll = res.nftBonus/Math.pow(10,18)
				this.totalxSPACEBounsOfAll = res.xBonus/Math.pow(10,18)
			})
			if (this.userAddress) {
				//console.log(1)
				getUserReward(this.contractAddress.devAddress,this.contractAddress.xTokenAddress,this.userAddress).then(userReward => {
					//console.log(userReward)
					this.myPendingbLPBonus = changeNumber( numberToFixed( this.$BigNumber(userReward._blpReward).div(Math.pow(10,18)) ) )
					this.myPendingBonus = changeNumber( numberToFixed( this.$BigNumber(userReward._nftReward).div(Math.pow(10,18)) ) )
					this.myPendingxSPACEBonus = changeNumber( numberToFixed( this.$BigNumber(userReward._xReward).div(Math.pow(10,18)) ) )
					userRewardInfo(this.contractAddress.devAddress,this.contractAddress.xTokenAddress,this.userAddress).then(rewardInfo => {
						//console.log(rewardInfo)
						this.mybLPBonus = changeNumber( numberToFixed( this.$BigNumber(userReward._blpReward).plus(rewardInfo.blpWithdrawn).div(Math.pow(10,18)) ))
						this.myTotalBonus = changeNumber( numberToFixed( this.$BigNumber(userReward._nftReward).plus(rewardInfo.nftWithdrawn).div(Math.pow(10,18)) ))
						this.myxSPACEBonus = changeNumber( numberToFixed( this.$BigNumber(userReward._xReward).plus(rewardInfo.xWithdrawn).div(Math.pow(10,18)) ))
						// mybLPBonus  myPendingbLPBonus
					})
				}).catch(error => {
					//console.log(error)
				})
			}
			getNFTTotalSupply(this.contractAddress.nftAddress,).then(res => {
				this.totalNFTs = res 
			})
		},
		withdrawBool(type){
			var arr = []
			if ( type == 0 ) {
				arr = [false,true,false]
				if (this.withdrawBool0Loading) {
					return false
				}
				this.withdrawBool0Loading = true
			} else if (type == 1) {
				arr = [true,false,false]
				if (this.withdrawBool1Loading) {
					return false
				}
				this.withdrawBool1Loading = true
			} else {
				arr = [false,false,true]
				if (this.withdrawBool2Loading) {
					return false
				}
				this.withdrawBool2Loading = true
			}
			//console.log(111111)
			judgeNetwork().then(res => {
                if (res == 0) {
					if ( type == 0 ) { this.withdrawBool0Loading = false } else if (type = 1) { this.withdrawBool1Loading = false } else { this.withdrawBool2Loading = false }
                    return false
                }
				withdrawBool(this.contractAddress.devAddress,...arr,this.userAddress).then(res => {
					//console.log(res)
					if ( type == 0 ) { this.withdrawBool0Loading = false } else if (type = 1) { this.withdrawBool1Loading = false } else { this.withdrawBool2Loading = false }
					this.getNonusData()
				}).catch(error => {
					if ( type == 0 ) { this.withdrawBool0Loading = false } else if (type = 1) { this.withdrawBool1Loading = false } else { this.withdrawBool2Loading = false }
				})
			})
			// withdrawBool
		},
		getSpaceBaseData(){
			getNodesLength(this.contractAddress.nodeAddress).then(res => { 
				this.totalSpacebase = res; 
			})
			userNumber(this.contractAddress.nodeAddress).then(res => {
				this.totalMembers = res
			})
			getNodeAward(this.contractAddress.nodeAddress).then(res => {
				this.totalRewardsOfSpacebaseSPACE = changeNumber( numberToFixed( res/Math.pow(10,18) ) )
			})
			getNodexAward(this.contractAddress.nodeAddress).then(res => {
				this.totalRewardsOfSpacebasexSPACE = changeNumber( numberToFixed( res/Math.pow(10,18) ) )
			})
			if (this.userAddress) {
				awardNodeUsers(this.contractAddress.nodeAddress,this.userAddress).then(res => {
					//console.log(res)
					this.myTotalRewardsSPACE = changeNumber( numberToFixed( res.totalAward/Math.pow(10,18) ) )
					this.myTotalRewardsxSPACE = changeNumber( numberToFixed( res.xtotalAward/Math.pow(10,18) ) )
					this.myPendingRewardsSPACE = changeNumber( numberToFixed( res.award/Math.pow(10,18) ) )
					this.myPendingRewardsxSPACE = changeNumber( numberToFixed( res.xaward/Math.pow(10,18) ) )
				})
			}
		},
		claimSpacebasePending(){
			if (this.claimSpacebaseLoading) {
				return false
			}
			this.claimSpacebaseLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.claimSpacebaseLoading = false
                    return false
                }
				handdleNodeWithdraw(this.contractAddress.nodeAddress,this.userAddress).then(res => {

				}).catch(error => {
					this.claimSpacebaseLoading = false
				})

			})
		},
		gowheel(event){
			if (event.deltaY > 0 && this.one==true) {
				this.$refs.swiper.next();
				this.one=false;
				setTimeout(()=>{
					this.one=true
				},500)
			}
			
			if (event.deltaY < 0 && this.one==true) {
				this.$refs.swiper.prev();
				this.one=false;
				setTimeout(()=>{
					this.one=true
				},500)
			}
    	}
    }
}
</script>